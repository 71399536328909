export default {

  methods: {
    go(url){
      this.$router.push(url).catch((err) => {});
    },


    // 下一页
    nextSelection() {
      const box2Element = this.$refs.page2;
      const box1Element = this.$refs.page1;
      const box2Rect = box2Element.getBoundingClientRect();
      const box1Rect = box1Element.getBoundingClientRect();
      // const currentScrollY = window.scrollY; // 获取当前页面的垂直滚动位置
      // console.log(currentScrollY);
      const distance = box2Rect.top - box1Rect.top; // 考虑当前页面滚动的距离

      const duration = 1000; // 滚动时间（毫秒）
      const startTime = performance.now();

      const animateScroll = (currentTime) => {
        const elapsed = currentTime - startTime;
        const progress = elapsed / duration;

        if (progress < 1) {
          window.scrollTo(0, distance * progress);
          window.requestAnimationFrame(animateScroll);
        } else {
          window.scrollTo(0, distance);
        }
      };

      window.requestAnimationFrame(animateScroll);
    },
  },
};
