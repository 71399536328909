import Vue from 'vue'
import App from './App.vue'
import router from './router'
import mixins from './mixins';
Vue.mixin(mixins)
import Vant from 'vant';
import '@/assets/css/common.scss'
import 'animate.css';
import 'vant/lib/index.css';
Vue.config.productionTip = false
import store from "./store"; // 引入 store 文件
import LineDot from "@/components/LineDot";
import axios from '@/util/axios';
import ElementUI from 'element-ui';
import {Message,Loading} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
Vue.prototype.$message = Message;
Vue.prototype.$http = axios

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.use(Vant)
Vue.use(ElementUI)
Vue.component('LineDot',LineDot)
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
