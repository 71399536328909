import _ from "axios";
import { Toast } from "vant";
import "vant/lib/toast/style";
import { Message } from "element-ui";
let baseURL = "http://www.cqhhrkj.com/";
const axios = _.create({
  baseURL,
  // process.env.NODE_ENV === "development"
  //   ? "http://cat.fwyouni.com/api"
  //   : process.env.REACT_APP_PROXY,
  headers: {
    "Content-Type": "application/json",
  },
});

// 发送拦截器
axios.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      config.data = JSON.stringify(config.data);
    }
    return config;
  },
  (error) => [error.toString()]
);

// 接收拦截器
axios.interceptors.response.use(
  (res) => {
    const {
      status,
      data: { code, message = "请求出错了，请稍后再试", data },
    } = res;

    if (status !== 200) {
      Toast(`连接失败 code:${status}`);
      return [`连接失败 code:${status}`];
    }

    if (code != 0) {
      Message.warning(message);
      return [{ code: code }];
    }

    return [null, data];
  },
  (error) => {
    const { response } = error;

    if (!response) {
      Toast("未连接到服务器");
      return ["未连接到服务器"];
    }

    if (response && response.status) {
      Toast("请求错误");

      // 还是需要返回错误原信息,因为接口需要传递发送信息与接口的信息
      return ["请求错误"];
    }
  }
);

export default axios;
export {
  baseURL
}
