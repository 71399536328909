import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "layout",
    component: () => import("@/layouts/index.vue"),
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("../views/index"),
        meta: {
          title: "和伙人科技",
        },
      },
      {
        path: "/product",
        name: "product",
        component: () => import("../views/product/index.vue"),
        meta: {
          title: "产品与服务",
        },
      },
      // {
      //   path: "/dynamic",
      //   name: "dynamic",
      //   component: () => import("../views/dynamic"),
      //   meta: {
      //     title: "最新动态",
      //   },
      // },
      {
        path: "/about",
        name: "about",
        component: () => import("../views/about"),
        meta: {
          title: "关于我们",
        },
      },
      // {
      //   path: "/dynamic/info",
      //   name: "dynamic_info",
      //   component: () => import("../views/dynamic/info"),
      //   meta: {
      //     isHidden: true,
      //     title: "最新动态详情",
      //   },
      // },
      {
        path: "/job",
        name: "job",
        component: () => import("../views/job"),
        meta: {
          title: "招贤纳士",
        },
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import("../views/contact"),
        meta: {
          title: "联系我们",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// 设置标题
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  window.scrollTo(0, 0);
  next();
});

// router.afterEach((to,from,next)=>{
//   window.screenTop(0,0)

// })
export default router;
export { routes };
