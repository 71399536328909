<template>
  <div class="line-dot flex-align-center">
    <span class="dot"></span>
    <span class="dot"></span>
    <span class="dot"></span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.line-dot {
  .dot {
    width: 0.4167vw;
    height: 0.4167vw;
    margin-right: 0.4167vw;
    background: #1857eb;
  }
}
</style>
